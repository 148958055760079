import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useMapStore } from "../../store/map";

import MoonLoader from "react-spinners/MoonLoader";

interface MyPopup {
    show: boolean;
    onHide: any;
    handleSave: any;
    nom: string;
    prenom: string;
    email: string;
    tel: string;
    coordx: string | null;
    coordy: string | null;
    comment: string;
    nomOrg?: string;
    localite?: string;
    rue?: string;
    num: string;
    showhide: string;
    fileImg: any;
    currentDate: string;
    isLoading: boolean;
}

const PopupErrAdress: React.FC<MyPopup> = ({
    onHide,
    show,
    handleSave,
    nom,
    prenom,
    email,
    tel,
    coordx,
    coordy,
    comment,
    nomOrg,
    localite,
    rue,
    num,
    showhide,
    fileImg,
    currentDate,
    isLoading,
}) => {
    const mapState = useMapStore.getState();

    const imgCap = mapState.imgCapture;

    return (
        <Modal
            className="tw-relative"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            size="xl"
        >
            {isLoading && (
                <div className="tw-absolute tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full tw-left-0 tw-bg-white tw-opacity-50 tw-z-50">
                    <MoonLoader color="#21254C" size={150} />
                </div>
            )}
            <Modal.Header closeButton onClick={onHide}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Récapitulatif de la demande du {currentDate}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Informations</h4>

                <Table
                    striped
                    bordered
                    hover
                    size="lg"
                    style={{ fontSize: 12 }}
                    responsive
                >
                    <thead>
                        <tr>
                            {nomOrg && showhide == "Personne morale" && (
                                <th>Organisme</th>
                            )}
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Localité</th>
                            <th>Rue</th>
                            <th>Numero</th>
                            <th>E-mail</th>
                            <th>Télephone</th>
                            <th>X</th>
                            <th>Y</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {nomOrg && showhide == "Personne morale" && (
                                <td>{nomOrg}</td>
                            )}
                            <td>{nom}</td>
                            <td>{prenom}</td>
                            <td>{localite}</td>
                            <td>{rue}</td>
                            <td>{num}</td>
                            <td>{email}</td>
                            <td>{tel}</td>
                            <td>{coordx}</td>
                            <td>{coordy}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="tw-mb-4 tw-flex tw-flex-col sm:tw-flex-row tw-justify-around">
                    <img src={fileImg} style={{ width: 472, height: 333 }} />

                    <img src={imgCap} style={{ width: 472, height: 333 }} />
                </div>

                <Table
                    striped
                    bordered
                    hover
                    size="lg"
                    style={{ fontSize: 12 }}
                    responsive
                >
                    <thead>
                        <tr>
                            <th style={{ fontSize: 16 }}>Commentaire</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: 13 }}>{comment}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={(e) => {
                        handleSave(e);
                        //handleVideImgMap();
                    }}
                    style={{ background: "#ED6E19" }}
                >
                    Valider
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PopupErrAdress;
