import { Link } from "gatsby";
import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import Layout from "../../layouts/layout";
import Map from "../../components/services/address/map/map";
import { Input, Textarea } from "react-daisyui";
import { useMapStore } from "../../store/map";
import axios from "axios";
import { getvoiebyid, saveSignalerErreur, urlGetCommune } from "../../api/api";
import PopupErrAdress from "../../components/popup/popuperradress";
import { toast } from "react-toastify";
import { oMap } from "../../store/omap";
import MapGenerator from "../../components/map/controls/capture/mapgeneratorimage";
import { useAddressStore } from "../../store/address";
import Captcha from "../../components/captcha/captcha";
import image from "../../images/pasimage.png";

interface optionInterface {
    value: string;
    label: string;
}
interface stateInterface {
    nomOrg: string;
    nom: string;
    prenom: string;
    email: string;
    tel: string;
    coordx: any;
    coordy: any;
    comment: string;
    fileMap: any;
    fileImg: any;

    optionsCommune: optionInterface[] | [];
    optionsRue: optionInterface[] | [];
    optionsNumero: optionInterface[] | [];
    idLocalite: string | null;
    idNumero: string | null;
    idRue: string | null;
}

interface communeInterface {
    id_localite: number;
    nom: string;
}

function ErreurAdresse() {
    const initialState = {
        nomOrg: "",
        nom: "",
        prenom: "",
        email: "",
        tel: "",
        coordx: "",
        coordy: "",
        comment: "",
        fileMap: "",
        fileImg: "",

        optionsCommune: [],
        optionsRue: [],
        optionsNumero: [],
        idLocalite: null,
        idRue: null,
        idNumero: null,
    };

    const { coordonneex, coordonneey, setCoordonneex, setCoordonneey } =
        useMapStore();
    const { infoFeatures } = useAddressStore();

    const [profileImg, setProfilImg] = useState<any>(image);
    const [showhide, setShowhide] = React.useState<string>("");
    const [formErrors, setFormErrors] = useState<stateInterface>(initialState);
    const [state, setState] = useState<stateInterface>(initialState);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [localite, setCommune] = useState<string>("");
    const [numero, setNumero] = useState<string>("");
    const [rue, setRue] = useState<string>("");
    const [idNum, setIdNum] = useState<string>("");
    const [nameRue, setNameRue] = useState<string>("");
    const [labelName, setLabelName] = useState("");
    const [feature, setFeature] = useState<any>();

    const mapState = useMapStore.getState();

    const { optionsCommune } = state;

    const date = new Date();

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const currentDate = `${year}-${month}-${day}`;

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    } as Intl.DateTimeFormatOptions;
    
    const dateText = new Intl.DateTimeFormat('fr-FR', options).format(date);

    const divStyles = {
        boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
        marginTop: "-6px",
    };

    const resetImage = (e: any) => {
        e.target.value = null;
        setProfilImg(image);
    };

    useEffect(() => {
        if (showhide === "Personne morale") {
            if (state.nomOrg) {
                document.getElementById("nomOrg")!.style.border = "1px solid #D5D5D6";
            }
        }
        if (state.nom) {
            document.getElementById("nom")!.style.border = "1px solid #D5D5D6";
        }
        if (state.prenom) {
            document.getElementById("prenom")!.style.border = "1px solid #D5D5D6";
        }
        if (state.email) {
            document.getElementById("email")!.style.border = "1px solid #D5D5D6";
        }
        if (state.tel) {
            document.getElementById("tel")!.style.border = "1px solid #D5D5D6";
        }
        if (state.comment) {
            document.getElementById("comment")!.style.border = "1px solid #D5D5D6";
        }
        if (profileImg != image) {
            document.getElementById("img")!.style.border = "1px solid #D5D5D6";
        }
    }, [state, profileImg]);

    useEffect(() => {
        if (!feature) return;

        const idVoie: string = feature.id_voie;
        axios
            .get(getvoiebyid(idVoie))
            .then((response: any) => {
                let newInfoFeatures: any = {};
                if (response.data.success !== false) {
                    const nameVoie = response.data.alias;
                    newInfoFeatures = {
                        id_numero: feature.id_numero,
                        id_localite: feature.id_localite,
                        id_voie: feature.id_voie,
                        numero: feature.numero,
                        alias: nameVoie,
                    };
                    setCommune(newInfoFeatures.id_localite);
                    setNumero(newInfoFeatures.numero);
                    setRue(newInfoFeatures.id_voie);
                    setIdNum(newInfoFeatures.id_numero);
                    setNameRue(newInfoFeatures.alias);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setCoordonneex(feature.x);
        setCoordonneey(feature.y);
    }, [feature]);

    useEffect(() => {
        if (infoFeatures.length > 0) {
            infoFeatures.map((item: any) => {
                setCommune(item.id_localite);
                setNumero(item.numero);
                setRue(item.id_voie);
                setIdNum(item.id_numero);
                setNameRue(item.alias);
            });
        } else {
            return;
        }
    }, [infoFeatures]);

    const handleshowhide = (event: React.FormEvent<EventTarget>) => {
        const getuser = (event.target as HTMLInputElement).value;
        setShowhide(getuser);
    };

    useEffect(() => {
        axios
            .get(urlGetCommune())
            .then((response) => {
                let data = response.data;

                let optionsCommune = [] as optionInterface[];
                if (data.success !== false) {
                    optionsCommune = response.data?.map(
                        (localite: communeInterface) => ({
                            value: localite.id_localite,
                            label: localite.nom,
                        })
                    );
                }

                setState((prevState) => ({
                    ...prevState,
                    optionsCommune,
                    bDisableComboCommune: false,
                    idLocalite: null,
                    optionsRue: [],
                    optionsNumero: [],
                    idRue: null,
                    idNumero: null,
                }));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const ref: any = useRef(null);

    //UPLOAD IMAGE
    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfilImg(reader.result);
            }
        };
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const communeValue: any = optionsCommune.filter(
        (option) => option.value === localite
    );

    const label: string = communeValue.map((item: any) => {
        return item.label;
    });

    useEffect(() => {
        setLabelName(label[0]);
    }, [label]);

    const handleChangeCom = (event: any) => {
        console.log(event.target.value);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        if (showhide === "Personne morale") {
            if (state.nomOrg) {
                document.getElementById("nomOrg")!.style.border = "1px solid #D5D5D6";
            }
        }
        if (name == "nom") {
            document.getElementById("nom")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "prenom") {
            document.getElementById("prenom")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "email") {
            document.getElementById("email")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "tel") {
            document.getElementById("tel")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "comment") {
            document.getElementById("comment")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "x") {
            document.getElementById("x")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "y") {
            document.getElementById("y")!.style.border = "1px solid #D5D5D6";
        }

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormErrors(validate(state));
        if (isCaptchaValid) {
            setIsSubmit(true);
        } else {
            toast.error("Valeur captcha incorrecte, veuillez rééssayer!", {
                position: "top-right",
                autoClose: 4000,
            });
            setReloadCaptcha(true);
        }
    };

    const handleSave = (event: any) => {
        event.preventDefault();

        const imgCap = mapState.imgCapture;

        const imgDoorString = state.fileImg.replace(/.*;base64,/, "");
        const imgDMapString = imgCap.replace(/.*;base64,/, "");

        setIsLoading(true)

        saveSignalerErreur({
            organisme: state.nomOrg,
            nom: state.nom,
            prenom: state.prenom,
            email: state.email,
            telephone: state.tel,
            localite: localite,
            rue: rue,
            numero: numero,
            x: coordonneex!.toString(),
            y: coordonneey!.toString(),
            commentaire: state.comment,
            map_file: imgDMapString,
            door_file: imgDoorString,
            date: currentDate,
        })
            .then((res) => {
                let data = res.data;
                if (data.success) {
                    toast.info(`L'enregistrement a été effectué avec succès sous le N° ${data.numero_demande}.`, {
                        position: "top-right",
                        autoClose: 4000,
                    });

                    resetImage(event);
                    setModalShow(false);

                    if (showhide === "Personne morale") {
                        setState((prev) => ({
                            ...prev,
                            nomOrg: "",
                        }));
                    }

                    setState((prev) => ({
                        ...prev,
                        nom: "",
                        prenom: "",
                        email: "",
                        tel: "",
                        comment: "",
                    }));

                    setCoordonneey("");
                    setCoordonneex("");
                    setState(initialState);
                    //console.log(data);
                } else if (data.msg == "limit") {
                    toast.error(
                        "Votre demande ne peut pas être enregistrée car vous avez déjà fait une demande que nous avons prise en compte",
                        {
                            position: "top-right",
                            autoClose: 4000,
                        }
                    );

                    setModalShow(false);
                    setCoordonneey("");
                    setCoordonneex("");
                    setState(initialState);
                } else {
                    toast.error("L'enregistrement n'a pas abouti !", {
                        position: "top-right",
                        autoClose: 4000,
                    });

                    console.log("No data found : " + data);
                }
                setIsLoading(false)
            })
            .catch((error: any) => {
                console.log(error);
            });

        // console.log('state : ', state)
    };

    const validate = (values: any) => {
        const errors = initialState;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (showhide === "Personne morale") {
            if (!values.nomOrg) {
                document.getElementById("nomOrg")!.style.border =
                    "1px solid red";
            }
        }
        if (!values.nom) {
            document.getElementById("nom")!.style.border = "1px solid red";
        }
        if (!values.prenom) {
            document.getElementById("prenom")!.style.border = "1px solid red";
        }
        if (!values.comment) {
            document.getElementById("comment")!.style.border = "1px solid red";
        }
        if (!coordonneex) {
            document.getElementById("x")!.style.border = "1px solid red";
        }
        if (!coordonneey) {
            document.getElementById("y")!.style.border = "1px solid red";
        }
        if (!values.email) {
            document.getElementById("email")!.style.border = "1px solid red";
        }
        if (!values.tel) {
            document.getElementById("tel")!.style.border = "1px solid red";
        }
        if (profileImg == image) {
            document.getElementById("img")!.style.border = "1px solid red";
            toast.error(
                "Votre demande est incomplète et ne peut être enregistrée car vous n’avez pas joint de photo de votre entrée",
                {
                    position: "top-right",
                    autoClose: 4000,
                }
            );
        }
        if (!regex.test(values.email)) {
            errors.email = "Email invalide!";
        }

        return errors;
    };

    const retourPage = (e: any) => {
        e.preventDefault();
        history.back();
    };

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight - 246]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    };

    const [width, height] = useWindowSize();

    if (width == 0 || height == 0) return null;

    const handleCaptchaValidation = (valid: any) => {
        setIsCaptchaValid(valid);
    };

    const showModal = (e: any) => {
        e.preventDefault();

        const fileImg = profileImg;

        setState((prev) => ({
            ...prev,
            fileImg,
        }));

        if (
            showhide === "Personne morale"
                ? !state.nomOrg
                : !state.nom ||
                  !state.prenom ||
                  !state.email ||
                  formErrors.email == "Email invalide!" ||
                  !state.tel ||
                  !state.comment ||
                  profileImg == image ||
                  !isCaptchaValid
        ) {
            handleSubmit(e);
        } else {
            setReloadCaptcha(true);
            handleSubmit(e);
            setModalShow(true);
            generateCaptureMap(coordonneex, coordonneey);
        }
        setTimeout(() => {
            setReloadCaptcha(false);
        }, 1000);
    };

    const generateCaptureMap = (lng: any, lat: any) => {
        const mapObj = oMap.getState().omap;

        const mapGenerator = new MapGenerator(mapObj!);
        mapGenerator.generate(lng, lat);
    };

    const style = {
        height: width >= 1495 ? height + 63 + "px" : "",
        width: "100%",
    };

    return (
        <Layout>
            <div
                className="tw-full tw-flex tw-justify-center tw-items-center tw-flex-wrap"
                style={style}
            >
                <div className="tw-flex tw-flex-col tw-px-7 tw-pb-5 lg:tw-pb-0 lg:tw-px-0 lg:tw-pl-5 tw-space-y-2 tw-justify-center tw-items-center">
                    <label className="tw-w-full tw-max-w-xl tw-text-center tw-text-2xl tw-font-bold tw-pb-2">
                        Signaler une erreur
                    </label>

                    <div className="tw-flex tw-space-x-2 tw-w-full tw-max-w-xl">
                        <select
                            name="type_personne"
                            defaultValue={"Personne physique"}
                            onChange={(e) => handleshowhide(e)}
                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                        >
                            <option value="Personne physique">
                                Personne physique
                            </option>
                            <option value="Personne morale">
                                Personne morale
                            </option>
                        </select>
                        <div className="tw-w-full tw-max-w-xs">
                            <Input
                                className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                placeholder="Date"
                                value={dateText}
                                disabled={true}
                            />
                        </div>
                    </div>
                    {coordonneey ? (
                        <></>
                    ) : (
                        <label className="tw-w-full tw-max-w-xl tw-text-center tw-italic tw-pb-2">
                            Cliquer sur un point dans la carte pour remplir les
                            champs correspondants
                        </label>
                    )}
                    <Input
                        className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                        placeholder="Localité"
                        value={labelName}
                        onChange={(e) => handleChangeCom(e)}
                        disabled={true}
                    />
                    <div className="tw-flex tw-w-full tw-max-w-xl tw-space-x-2">
                        <Input
                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] focus:tw-outline-[#2684FF] tw-w-6/12 tw-max-w-xl"
                            placeholder="Voie"
                            value={nameRue}
                            disabled={true}
                        />
                        <Input
                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] focus:tw-outline-[#2684FF] tw-w-6/12 tw-max-w-xl"
                            placeholder="N°"
                            value={numero}
                            disabled={true}
                        />
                    </div>
                    {showhide === "Personne morale" ? (
                        <>
                            <div className="tw-w-full tw-max-w-xl">
                                <Input
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    placeholder="Nom de l'organisme"
                                    onChange={handleChange}
                                    value={state.nomOrg}
                                    name="nomOrg"
                                    id="nomOrg"
                                />
                                <p className={`tw-text-[#FF0303] tw-text-xs `}>
                                    {formErrors.nomOrg}
                                </p>
                            </div>
                            <div className="tw-w-full tw-max-w-xl">
                                <Input
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    placeholder="Nom du représentant"
                                    onChange={handleChange}
                                    value={state.nom}
                                    name="nom"
                                    id="nom"
                                />
                                <p className="tw-text-[#FF0303] tw-text-xs">
                                    {formErrors.nom}
                                </p>
                            </div>
                            <div className="tw-w-full tw-max-w-xl">
                                <Input
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    placeholder="Prénom du représentant"
                                    onChange={handleChange}
                                    value={state.prenom}
                                    name="prenom"
                                    id="prenom"
                                />
                                <p className="tw-text-[#FF0303] tw-text-xs">
                                    {formErrors.prenom}
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="tw-w-full tw-max-w-xl">
                                <Input
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    placeholder="Nom"
                                    onChange={handleChange}
                                    value={state.nom}
                                    id="nom"
                                    name="nom"
                                />
                                <p className="tw-text-[#FF0303] tw-text-xl">
                                    {formErrors.nom}
                                </p>
                            </div>
                            <div className="tw-w-full tw-max-w-xl">
                                <Input
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    placeholder="Prénom"
                                    onChange={handleChange}
                                    value={state.prenom}
                                    id="prenom"
                                    name="prenom"
                                />
                                <p className="tw-text-[#FF0303] tw-text-xs">
                                    {formErrors.prenom}
                                </p>
                            </div>
                        </>
                    )}
                    <div className="tw-w-full tw-max-w-xl">
                        <Input
                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                            placeholder="Email"
                            onChange={handleChange}
                            value={state.email}
                            id="email"
                            name="email"
                        />
                        <p className="tw-text-[#FF0303] tw-text-xs">
                            {formErrors.email}
                        </p>
                    </div>
                    <div className="tw-w-full tw-max-w-xl">
                        <Input
                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                            placeholder="Télephone"
                            onChange={handleChange}
                            value={state.tel}
                            name="tel"
                            id="tel"
                        />
                        <p className="tw-text-[#FF0303] tw-text-xs">
                            {formErrors.tel}
                        </p>
                    </div>
                    <div className="tw-flex tw-space-x-2 tw-w-full tw-max-w-xl">
                        <div className="tw-w-full tw-max-w-xl">
                            <Input
                                className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xs focus:tw-outline-[#2684FF] "
                                placeholder="X"
                                value={coordonneex ? coordonneex : state.coordx}
                                onChange={handleChange}
                                disabled={true}
                                id="x"
                                name="x"
                            />
                            <p className="tw-text-[#FF0303] tw-text-xs">
                                {formErrors.coordx}
                            </p>
                        </div>
                        <div className="tw-w-full tw-max-w-xl">
                            <Input
                                className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xs focus:tw-outline-[#2684FF] "
                                placeholder="Y"
                                value={coordonneey ? coordonneey : state.coordy}
                                onChange={handleChange}
                                disabled={true}
                                id="y"
                                name="y"
                            />
                            <p className="tw-text-[#FF0303] tw-text-xs">
                                {formErrors.coordy}
                            </p>
                        </div>
                    </div>
                    <div className="tw-w-full tw-max-w-xl">
                        <Textarea
                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                            placeholder="Commentaire"
                            onChange={handleChange}
                            value={state.comment}
                            name="comment"
                            id="comment"
                        />
                        <p className="tw-text-[#FF0303] tw-text-xs">
                            {formErrors.comment}
                        </p>
                    </div>

                    <Captcha
                        onCaptchaValidation={handleCaptchaValidation}
                        reload={reloadCaptcha}
                    />

                    <div className="tw-flex tw-justify-around tw-w-full tw-max-w-xl tw-mt-2 tw-space-x-2">
                        <button
                            onClick={(e) => retourPage(e)}
                            className="tw-btn tw-btn-sm tw-max-w-xl tw-font-normal tw-w-[47%] tw-rounded-lg tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                        >
                            Annuler
                        </button>
                        <button
                            onClick={(e) => showModal(e)}
                            className="tw-btn tw-btn-sm tw-max-w-xs tw-font-normal tw-w-[47%] tw-rounded-lg tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                        >
                            Soumettre
                        </button>
                    </div>
                </div>

                <div className="page tw-w-auto tw-max-w-xl">
                    <div className="container">
                        <div className="img-holder">
                            <img
                                src={profileImg}
                                alt=""
                                id="img"
                                className="img"
                            />
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            name="image-upload"
                            id="input"
                            onChange={imageHandler}
                            ref={ref}
                        />
                        <div className="">
                            <div className="label">
                                <label
                                    className="image-upload tw-text-base"
                                    htmlFor="input"
                                >
                                    Photo de votre porte
                                </label>
                            </div>
                            <div className="label">
                                <label
                                    className="image-upload tw-text-base"
                                    onClick={(e) => resetImage(e)}
                                >
                                    Annuler
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" tw-w-full lg:tw-mt-[3px] lg:tw-w-[40%] tw-h-[76%] lg:tw-pr-5">
                    <div className="tw-mb-3 tw-text-center">
                        <p className="tw-font-bold">Carte de localisation</p>
                    </div>
                    <div
                        style={divStyles}
                        className="tw-flex tw-w-[100%] tw-h-96 tw-px-7 tw-pb-7 lg:tw-pb-0 lg:tw-px-0 lg:tw-h-full tw-rounded-md tw-items-center"
                    >
                        <Map bNewDemande={false} setErrorFeature={setFeature} />
                    </div>
                </div>

                <PopupErrAdress
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false);
                        mapState.setImgCapture("");
                    }}
                    handleSave={handleSave}
                    localite={labelName}
                    rue={nameRue}
                    num={numero}
                    nom={state.nom}
                    prenom={state.prenom}
                    email={state.email}
                    tel={state.tel}
                    fileImg={state.fileImg}
                    nomOrg={state.nomOrg}
                    comment={state.comment}
                    coordx={coordonneex}
                    coordy={coordonneey}
                    showhide={showhide}
                    currentDate={dateText}
                    isLoading={isLoading}
                />
            </div>
        </Layout>
    );
}

export default ErreurAdresse;
